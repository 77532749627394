import React, { useEffect } from "react";
import Sidebar from "./Sidebar";

export default function DashboardWrapper({ children }) {
  useEffect(() => {
    document.querySelector(".profile-main-loader").style.display = "none";
  }, []);
  return (
    <div className="dashboard__wrapper">
      <Sidebar />
      <main>{children}</main>
    </div>
  );
}
